import {
  Flex, Grid
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import FilesFoldersTable from "./components/FilesFoldersTable";
import ExpandedTable from "./components/ExpandedTable";
import { filesData } from "../../../variables/mockFilesJSON";

export default function Documents() {
  // const iconBoxInside = useColorModeValue("white", "white");
  const [data, setData] = useState({ files: [], folders: {} });
  const [folderIsExpanded, setFolderIsExpanded] = useState(false);
  const [expandedFolderName, setExpandedFolderName] = useState("");
  const [expandedFolderData, setExpandedFolderData] = useState({ files: [], folders: {} });

  useEffect(() => {
    const parsedData = JSON.parse(filesData.body);
    setData(parsedData);
  }, []);

  return (
      <Flex flexDirection="column" pr="30px" pt={{ base: "120px", md: "75px" }}>
        <Grid
            templateColumns={"1fr"}
            templateRows={{ md: "1fr auto", lg: "1fr" }}
            my="13px"
            gap="15px"
        >
          {folderIsExpanded ? (
              <ExpandedTable
                  title={expandedFolderName}
                  captions={["Name", "Last Modified", ""]}
                  data={expandedFolderData}
                  folderIsExpanded={folderIsExpanded}
                  setFolderIsExpanded={setFolderIsExpanded}
                  setExpandedFolderName={setExpandedFolderName}
                  setExpandedFolderData={setExpandedFolderData}
              />
          ) : (
              <FilesFoldersTable
                  title={"Documents"}
                  captions={["Name", "Last Modified", ""]}
                  data={data}
                  folderIsExpanded={folderIsExpanded}
                  setFolderIsExpanded={setFolderIsExpanded}
                  setExpandedFolderName={setExpandedFolderName}
                  setExpandedFolderData={setExpandedFolderData}
              />
          )}
        </Grid>
      </Flex>
  );
}
